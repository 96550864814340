$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': window.csrf
    }
});


// Gestion des dates
$(function(){
    
    window.DateManager = {
        start_date : window.localStorage.getItem('start_date'),
        end_date : window.localStorage.getItem('end_date'),
        get : function(){
            return {
                'start_date' : this.start_date,
                'end_date' : this.end_date
            };
        }
    };
    
    
    if(window.DateManager.start_date == null || window.DateManager.end_date == null){
        window.DateManager.start_date = moment().subtract(7, 'days');
        window.DateManager.end_date = moment();
        
        window.localStorage.setItem('start_date', window.DateManager.start_date.format('YYYY-MM-DD'));
        window.localStorage.setItem('end_date', window.DateManager.end_date.format('YYYY-MM-DD'));
    }else{
        window.DateManager.start_date = moment(window.DateManager.start_date, 'YYYY-MM-DD');
        window.DateManager.end_date = moment(window.DateManager.end_date, 'YYYY-MM-DD');
    }
    
    
    $('#header-start-date').val(window.DateManager.start_date.format('DD/MM/YYYY'));
    $('#header-end-date').val(window.DateManager.end_date.format('DD/MM/YYYY'));
    
    
    
    
    $('#header-start-date,#header-end-date').datepicker({
        format: 'dd/mm/yyyy',
        language: "fr"
    }).on('changeDate', function(e){
        if(this.id == 'header-start-date'){
            window.DateManager.start_date = moment(e.date);
            window.localStorage.setItem('start_date', window.DateManager.start_date.format('YYYY-MM-DD'));
        }else{
            window.DateManager.end_date = moment(e.date);
            window.localStorage.setItem('end_date', window.DateManager.end_date.format('YYYY-MM-DD'));
        }
        
        
        $(document).trigger('MainDatesChange', window.DateManager.get());
        return true;
    });
    
    
    $(document).trigger('MainDatesChange', window.DateManager.get());
});